// This component will be used to upload the file and show the insights
// Initially there will be a upload button and a file input field to 
// upload the file. Once the user clicks on upload button we expect the api
// to take 4-5 seconds to process the file and return the insights.
// During this time we will show a loader. Once the insights are returned
// we will show the insights.
// The page will basically transition from upload button to loader to insights.
import './Upload.css';
import Navbar from './Navbar';
import React, { useState, useEffect, useCallback } from 'react';

interface Insight {
  insight: string;
}

interface Suggestion {
  suggestion: string;
}

interface ApiResponse {
  // Define the structure of your ApiResponse here, insights
  // will be list of strings and suggestions will be list of strings
  insights: Insight[];
  suggestions: Suggestion[];
  transcript: string;
}

const Upload: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [insights, setInsights] = useState<ApiResponse | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('file changed');
    const files = event.target.files;
    console.log('files are', files)
    if (files && files.length > 0) {
      console.log('setting file');
      setFile(files[0]);
      console.log('file is', file)
    }
    console.log('calling upload file');
  };

  // Inside your component
  const uploadFile = useCallback(async () => {
    console.log('upload file called');
    if (!file) return;
    console.log('uploading file');
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await fetch(process.env.REACT_APP_PROCESS_URL || 'https://api.altorlab.com/processmock/', {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        console.log('data is', data)
        setInsights(data);
      } else {
        console.error('Failed to process file');
      }
    } catch (error) {
      // Your catch block content
    } finally {
      setLoading(false);
    }
  }, [file]); // Include all dependencies of uploadFile here

  useEffect(() => {
    if (file) {
      uploadFile();
    }
  }, [file, uploadFile]); // Now include uploadFile in the dependency array

  return (
    <div className='Mainimg'>
      <Navbar />
      <div>
        {loading ? (
          <div className="center-container">
            <div className="spinner"></div>
          </div>
        ) : insights ? (
          <div>
            <a href={insights.transcript} target="_blank" rel="noopener noreferrer">
              <button className='transcript-button'>Transcript</button>
            </a>
            <div className="center-container-insights">
              <div>
                <div className="suggestions-container">
                  <h1>Upselling suggestions for the customer</h1>
                  <ul>
                    {insights.suggestions.map((suggestion, index) => (
                      <li><div className='insights-text' dangerouslySetInnerHTML={{ __html: suggestion.suggestion }}></div></li>
                    ))}
                  </ul>
                </div>
                <br></br>
                <div className='insights-container'>
                  <h1>Insights:</h1>
                  <ul>
                    {insights.insights.map((insight, index) => (
                      <li><div className='insights-text'>{insight.insight}</div></li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // <div className="upload-container">
          //   <input type="file" onChange={handleFileChange} />
          //   <button onClick={uploadFile} disabled={!file}>
          //     Upload
          //   </button>
          //   <div className="file-upload upload-container">
          //     <label htmlFor="upload" className="file-upload__label">Css only file upload button</label>
          //     <input id="upload" className="file-upload__input" type="file" name="file-upload"  />
          //   </div>
          // </div>
          <div className="upload-container file-upload">
            <h1>AltorLabs: Customer interaction data analytics</h1>
            <h2>
              <div className='upload-body'>
                Environment: Demo | Customer: Yes bank
              </div>
            </h2>
            <br></br>
            <label htmlFor="upload" className="file-upload__label">Upload</label>
            <input
              id="upload"
              className="file-upload__input"
              type="file"
              name="file-upload"
              onChange={handleFileChange} // Added onChange event handler
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Upload;
