import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Upload from './Upload';
import Sop from './Sop';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Upload />} />
        <Route path="/sop" element={<Sop />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);