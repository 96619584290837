// The page will basically transition from upload button to loader to insights.
import './Sop.css';
import Navbar from './Navbar';
import React, { useState, useEffect, useCallback } from 'react';

interface Faq {
  question: string;
  answer: string;
}

interface FaqResponse {
  title: string;
  faqs: Faq[];
}

const Sop: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [faqdata, setFaqdata] = useState<FaqResponse | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('file changed');
    const files = event.target.files;
    console.log('files are', files)
    if (files && files.length > 0) {
      console.log('setting file');
      setFile(files[0]);
      console.log('file is', file)
    }
    console.log('calling upload file');
  };

  // Inside your component
  const uploadFile = useCallback(async () => {
    console.log('upload file called');
    if (!file) return;
    console.log('uploading file');
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await fetch(process.env.REACT_APP_SOP_URL || 'https://api.altorlab.com/sopmock/', {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        console.log('data is', data)
        setFaqdata(data);
      } else {
        console.error('Failed to process file');
      }
    } catch (error) {
      // Your catch block content
    } finally {
      setLoading(false);
    }
  }, [file]); // Include all dependencies of uploadFile here

  useEffect(() => {
    if (file) {
      uploadFile();
    }
  }, [file, uploadFile]); // Now include uploadFile in the dependency array

  return (
    <div className='Mainimg'>
      <Navbar />
      <div>
        {loading ? (
          <div className="sop-center-container">
            <div className="spinner"></div>
          </div>
        ) : faqdata ? (
          <div>
            <div className="sop-container">
              <div>
                <div><h1><center>{faqdata.title}</center></h1></div>
                {/* faqdata.faqs will be a list of questions and answers, we want to display them one by one with question in bold */}
                {faqdata.faqs.map((faq, index) => (
                  <div key={index} className="faq">
                    <div className="question"><h2>{faq.question}</h2></div>
                    <div className="answer"><h3>{faq.answer}</h3></div>
                  </div>
                ))}
                <br></br>
                <button onClick={() => window.print()} className='transcript-button'>Faq Download</button>
                <br></br>
              </div>
            </div>
          </div>
        ) : (
          <div className="upload-container file-upload">
            <h1>AltorLabs: Standard operating proceduce to FAQ</h1>
            <h2>
              <div className='upload-body'>
                Environment: Demo | Customer: Yes bank
              </div>
            </h2>
            <br></br>
            <label htmlFor="upload" className="file-upload__label">Upload</label>
            <input
              id="upload"
              className="file-upload__input"
              type="file"
              name="file-upload"
              onChange={handleFileChange} // Added onChange event handler
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Sop;
