import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Navbar.css'; // Importing the CSS for styling

const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
      <Link to="https://altorlab.com">
        <img src="https://altorlab.blob.core.windows.net/assets/logo3.png" alt="Logo" className="navbar-logo" />
      </Link>
    </nav>
  );
};

export default Navbar;